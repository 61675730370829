import React from 'react';
import { Container, Grid, Header as SuiHeader, Image } from 'semantic-ui-react';
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';

import Header from '../components/header';
import Layout from '../components/layout';
// import KetButton from "../components/ket-button";
import SEO from '../components/seo';

import './brass-deflector.css';

import brassDeflectorImage from '../images/products-rifle-1000x750.jpg';
import brassDeflectorBlack from '../images/brass-deflector-black.jpg';
import brassDeflectorBlackGray from '../images/brass-deflector-black-gray.jpg';
import brassDeflectorWolfGray from '../images/brass-deflector-wolf-gray.jpg';
import brassDeflectorSafetyOrange from '../images/brass-deflector-safety-orange.jpg';
import brassDeflectorOdGreen from '../images/brass-deflector-od-green.jpg';
import brassDeflectorCoyote from '../images/brass-deflector-coyote.jpg';

import posterImage from '../images/poster-image.jpg';

const BrassDeflector = () => (
  <Layout>
    <SEO title="Brass-Deflector" />
    <Header />
    <Container className="container__ket open-sans-regular brass-deflector-main">
      <Grid className="brass-deflector-info" stackable padded>
        <Grid.Row centered>
          <Grid.Column width="8" textAlign="center">
            <Image alt="Brass Clip PR21 Colors" src={brassDeflectorImage} />
            <Grid className="brass-deflector-colors" columns="6" centered>
              <Grid.Row style={{ height: '6.3rem' }}>
                <Grid.Column textAlign="center" verticalAlign="middle">
                  {/* <a
                    href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07VQ2TG2J/ref=sr_1_30?dchild=1&keywords=brass+catcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
                    rel="noreferrer noopener"
                    target="_blank"
                  > */}
                  <Image
                    alt="Brass Deflector Black"
                    src={brassDeflectorBlack}
                    bordered
                    title="Black"
                  />
                  {/* </a> */}
                </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle">
                  {/* <a
                    href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07WRNZFQF/ref=sr_1_30?dchild=1&keywords=brass%2Bcatcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
                    rel="noreferrer noopener"
                    target="_blank"
                  > */}
                  <Image
                    alt="Brass Deflector Black Gray"
                    src={brassDeflectorBlackGray}
                    bordered
                    title="Black Gray"
                  />
                  {/* </a> */}
                </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle">
                  {/* <a
                    href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07WWWHRSR/ref=sr_1_30?dchild=1&keywords=brass%2Bcatcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
                    rel="noreferrer noopener"
                    target="_blank"
                  > */}
                  <Image
                    alt="Brass Deflector Wolf Gray"
                    src={brassDeflectorWolfGray}
                    bordered
                    title="Wolf Gray"
                  />
                  {/* </a> */}
                </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle">
                  {/* <a
                    href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07WVWRFM7/ref=sr_1_30?dchild=1&keywords=brass%2Bcatcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
                    rel="noreferrer noopener"
                    target="_blank"
                  > */}
                  <Image
                    alt="Brass Deflector Safety Orange"
                    src={brassDeflectorSafetyOrange}
                    bordered
                    title="Safety Orange"
                  />
                  {/* </a> */}
                </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle">
                  {/* <a
                    href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07WNTCYF4/ref=sr_1_30?dchild=1&keywords=brass%2Bcatcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
                    rel="noreferrer noopener"
                    target="_blank"
                  > */}
                  <Image
                    alt="Brass Deflector OD Green"
                    src={brassDeflectorOdGreen}
                    bordered
                    title="OD Green"
                  />
                  {/* </a> */}
                </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle">
                  {/* <a
                    href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07WVWX96N/ref=sr_1_30?dchild=1&keywords=brass%2Bcatcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
                    rel="noreferrer noopener"
                    target="_blank"
                  > */}
                  <Image
                    alt="Brass Deflector Coyote"
                    src={brassDeflectorCoyote}
                    bordered
                    title="Coyote"
                  />
                  {/* </a> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width="8">
            <SuiHeader as="h3">KET Brass-Deflector</SuiHeader>
            <div>
              A new alternative to traditional brass catchers that is designed to provide military,
              law enforcement and performance-focused shooters with a lightweight and easy to attach
              alternative device for training session. We're solving problems and limitations that
              brass catchers have not been able to address—heat resistance, easy installation, light
              weight, non-intrusive capacity maximum, and high quality. Consistently blocks spent
              ammo from flying away from the shooter.
            </div>
            <ul>
              <li>
                <span className="open-sans-bold">Universal</span> - easily snaps onto Scopes and
                Picatinny Rails.
              </li>
              <li>
                <span className="open-sans-bold">Simple Setup</span> - set includes snap-on mounts.
              </li>
              <li>
                <span className="open-sans-bold">Will not interfere</span> with moving parts or
                weigh down firearm.
              </li>
              <li>
                <span className="open-sans-bold">Heat resistant</span>, lightweight, and no capacity
                maximum.
              </li>
              <li>
                <span className="open-sans-bold">Drops shells nearby</span> for easy cleanup - great
                for shooting ranges, reloading, safety training, and performance shooters alike.
              </li>
              <li>
                <span className="open-sans-bold">100% Made in USA</span>
              </li>
            </ul>
            {/* <div className="brass-deflector-purchase">
              <a
                className="brass-deflector-purchase-button"
                href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07VQ2TG2J/ref=sr_1_30?dchild=1&keywords=brass+catcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
                rel="noreferrer noopener"
                target="_blank"
              >
                <KetButton>See Price on Amazon</KetButton>
              </a>
            </div> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <div className="container__ket-blue container__ket-blue__padded">
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column width="8" textAlign="center">
            <Player poster={posterImage}>
              <BigPlayButton position="center" />
              <source src="../videos/brass-deflector-video.mp4" />
              <ControlBar>
                <VolumeMenuButton />
                <CurrentTimeDisplay disabled />
                <TimeDivider disabled />
                <PlaybackRateMenuButton disabled />
              </ControlBar>
            </Player>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
    {/* <Container className="container__ket open-sans-regular" textAlign="center">
      <a
        href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07VQ2TG2J/ref=sr_1_30?dchild=1&keywords=brass+catcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
        rel="noreferrer noopener"
        target="_blank"
      >
        <KetButton>See Price on Amazon</KetButton>
      </a>
    </Container> */}
  </Layout>
);

export default BrassDeflector;
