import React from 'react';
import PropTypes from 'prop-types';

import './layout.css';

const Layout = ({ children }) => (
  <div className="layout">
    <div className="layout-main">{children}</div>
    <div className="layout-footer">
      <div className="layout-footer-copy">&copy; 2022 All Rights Reserved. Patent Pending.</div>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
