import React from 'react';
import { Link } from 'gatsby';
import { Image } from 'semantic-ui-react';

import './header.css';

import ketLogo from '../images/ket-logo-white-412.png';

const Header = () => (
  <div className="ket-header">
    <div className="ket-header-links">
      <div className="ket-header-logo">
        <Link to="/">
          <Image src={ketLogo} />
        </Link>
      </div>
      <div>
        <Link activeClassName="active" partiallyActive={true} to="/brass-deflector">
          BRASS-DEFLECTOR
        </Link>
      </div>
      <div>
        <Link activeClassName="active" partiallyActive={true} to="/setup">
          SETUP
        </Link>
      </div>
      <div>
        <Link activeClassName="active" partiallyActive={true} to="/contact-us">
          CONTACT US
        </Link>
      </div>
    </div>
  </div>
);

export default Header;
